import React from "react";

function Faq() {
  return (
    <div className="middle">
      <div className="say-with-men">
        <div className="container">
          <div
            className="say-with-hedding"
            data-aos="fade-down"
            data-aos-duration={2000}
          >
            {/* <h6> FAQs</h6> */}
            <hr className="border-a" />
            <h4> FAQs</h4>
            {/* <img class="border-icon" src="images/border-icon.png"> */}
          </div>
          <div className="row why-book-center align-items-center">
            <div className="col-lg-12 col-md-12">
              <div
                className="say-with-left"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button accordion-button-two"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        1. How can I make a reservation?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqs-text">
                        <p>
                          Reservations can be made through our website, by
                          phone, or via email. We also accept bookings through
                          popular travel platforms like Booking.com, Airbnb,
                          Makemytrip, Goibibo, Trivago.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button accordion-button-two collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        2.What payment methods are accepted?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqs-text">
                        <p>
                          We accept bank transfers, UPI and cash payments. For
                          online bookings, payment options depend on the
                          platform used.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button accordion-button-two collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        3.Is a deposit required?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqs-text">
                        <p>
                          Yes, a deposit is required to confirm your booking.
                          The amount will be specified at the time of
                          reservation.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button accordion-button-two collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse4"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        4.What are the check-in and check-out times?
                      </button>
                    </h2>
                    <div
                      id="collapse4"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqs-text">
                        <p>
                          {" "}
                          Check-in is from 2:00 PM, and check-out is by 11:00
                          AM. Early check-ins or late check-outs may be arranged
                          upon request and availability
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button accordion-button-two collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse5"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        5.Do you offer 24-hour check-in?
                      </button>
                    </h2>
                    <div
                      id="collapse5"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqs-text">
                        <p>
                          Yes, for late arrivals or early morning check-ins,
                          please inform us in advance to make arrangements.
                          Charges May Applicable.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button accordion-button-two collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse6"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        6. What facilities are available in the rooms?
                      </button>
                    </h2>
                    <div
                      id="collapse6"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqs-text">
                        <p>
                          {" "}
                          Each room is equipped with air conditioning, Wi-Fi, a
                          flat-screen TV, a private bathroom, and complimentary
                          toiletries. Additional amenities like a kettle are
                          available in rooms.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button accordion-button-two collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse7"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        7. Do you provide daily housekeeping?
                      </button>
                    </h2>
                    <div
                      id="collapse7"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqs-text">
                        <p>
                          {" "}
                          Yes, daily housekeeping is included in your stay.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button accordion-button-two collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse8"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        8.Is Wi-Fi available, and is it free?
                      </button>
                    </h2>
                    <div
                      id="collapse8"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqs-text">
                        <p>
                          {" "}
                          Yes, complimentary high-speed Wi-Fi is available
                          throughout the property.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button accordion-button-two collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse9"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        9. Is breakfast included in the stay?
                      </button>
                    </h2>
                    <div
                      id="collapse9"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqs-text">
                        <p>
                          {" "}
                          Depending on your booking plan, breakfast may be
                          included. We also offer breakfast as an add-on at a
                          nominal charge.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button accordion-button-two collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse10"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        10.Do you provide airport transfers?
                      </button>
                    </h2>
                    <div
                      id="collapse10"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqs-text">
                        <p>
                          {" "}
                          Yes, airport transfers can be arranged for an
                          additional fee. Please inform us at least 24 hours in
                          advance.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button accordion-button-two collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse11"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        11. What is your cancellation policy?
                      </button>
                    </h2>
                    <div
                      id="collapse11"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqs-text">
                        <p>
                          {" "}
                          Cancellations made 72 hours before check-in are
                          partially refundable. Late cancellations or no-shows
                          may incur a fee.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button accordion-button-two collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse12"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        12 Do you allow pets?
                      </button>
                    </h2>
                    <div
                      id="collapse12"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqs-text">
                        <p> Pets are not allowed in hotel premises.</p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button accordion-button-two collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse13"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        13.Is smoking permitted?
                      </button>
                    </h2>
                    <div
                      id="collapse13"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqs-text">
                        <p>
                          {" "}
                          Smoking is not allowed in the rooms. However,
                          designated smoking areas are available on the
                          premises.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button accordion-button-two collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse14"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        14. How can I contact you for more information?
                      </button>
                    </h2>
                    <div
                      id="collapse14"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqs-text">
                        <p>
                          {" "}
                          You can reach us at 6350482876 or megha@skymmd.com, or
                          via WhatsApp.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
