import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "bootstrap";

function Header() {
  const offcanvasRef = useRef(null);

  useEffect(() => {
    // Initialize Bootstrap Offcanvas
    const offcanvasInstance = new Offcanvas(offcanvasRef.current);

    // Clean up offcanvas instance on unmount
    return () => {
      offcanvasInstance.dispose();
    };
  }, []);

  const handleLinkClick = () => {
    // Smooth scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Close the offcanvas menu
    if (offcanvasRef.current) {
      const offcanvasInstance = Offcanvas.getInstance(offcanvasRef.current);
      if (offcanvasInstance) {
        offcanvasInstance.hide();
      }
    }

    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) {
      backdrop.classList.remove("show");
    }
  };

  return (
    <div>
      <div className="cursor" />
      <div className="header">
        <div className="navbar-contact">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-2 col-md-3 col-6">
                <div className="navbar-icon-contact">
                  <h2>
                    <i className="fa-solid fa-phone-volume" /> +91 6350482876
                  </h2>
                </div>
              </div>
              <div className="col-lg-8 col-md-6 col-6">
                <div className="navbar-icon-contact"></div>
              </div>

              <div className="col-lg-2 col-md-3 col-6">
                <div className="navbar-icon-contact address-sm-none">
                  <h2>
                    
                  <a href="mailto:info@skymmd.com">info@skymmd.com</a>

                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="navbar_top" className="top-nev">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-2 col-4 text-center">
                <div className="nav-left">
                  <h2>
                    
                    <div
                      className="offcanvas offcanvas-start offcanvas-start-color"
                      tabIndex={-1}
                      id="offcanvasExample"
                      aria-labelledby="offcanvasExampleLabel"
                      ref={offcanvasRef}
                    ></div>
                  </h2>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-4 text-center">
                <div className="nav-logo">
                  <Link to="/Home">
                    <img
                      className="img-fluid"
                      src="images/logo.png"
                      alt="Logo"
                      style={{ height: "100px", width: "100px" }}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-4 text-center">
                <div className="nav-right">
                  <Link className="book-now-btn book-now-btn-2" to={"/contact"}>
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="middle-nev">
          <div className="container">
            <div className="row">
              <div className="middle-nev-text">
                <ul>
                  <li>
                    <Link to="/Home" onClick={handleLinkClick}>
                      Home
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/Aboutus" onClick={handleLinkClick}>
                      About Us
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/Gallery" onClick={handleLinkClick}>
                      Gallery
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/Offer" onClick={handleLinkClick}>
                      Special Offer
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/Contact" onClick={handleLinkClick}>
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
