import React from "react";
import mmdg1 from "../../utils/Images/mmdg1.jpg";
import mmdg2 from "../../utils/Images/mmdg2.jpg";
import mmdg3 from "../../utils/Images/mmdg3.jpg";
import mmdg4 from "../../utils/Images/mmdg4.jpg";
import mmdg5 from "../../utils/Images/mmdg5.jpg";
import mmdg6 from "../../utils/Images/mmdg6.jpg";
import mmdg7 from "../../utils/Images/mmdg7.jpg";
import mmdg8 from "../../utils/Images/mmdg8.jpg";
import mmdg9 from "../../utils/Images/mmdg9.jpg";
import mmdg10 from "../../utils/Images/mmdg10.jpg";
import mmdg11 from "../../utils/Images/mmdg11.jpg";











function Gallery() {
  return (
    <div className="middle">
      <div className="say-with-men">
        <div className="container">
          <div
            className="say-with-hedding"
            data-aos="fade-down"
            data-aos-duration={3000}
          >
            <h4>Gallery</h4>
            {/* <hr className="border-a" /> */}
            {/* <h4>MMD Gallery</h4> */}
            {/* <img class="border-icon" src="images/border-icon.png"> */}
          </div>
          <div className="row why-book-center">
            <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src={mmdg1}/>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src={mmdg2}/>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid"src={mmdg3}/>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src={mmdg4}/>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src={mmdg11}/>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src={mmdg5}/>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src={mmdg6}/>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src={mmdg7}/>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src={mmdg8}/>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src={mmdg9}/>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-md-6">
              <div className="room-types-men room-types-men-two">
                <img className="img-fluid" src={mmdg10}/>
              </div>
            </div> */}
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
