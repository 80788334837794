import React from "react";
import mmdimg1 from "../../utils/Images/mmdimg1.png";
import mmdimg2 from "../../utils/Images/mmdimg2.png";
import mmdimg3 from "../../utils/Images/mmdimg3.png";
import mmdimg4 from "../../utils/Images/mmdimg4.jpeg";
import mmdimg5 from "../../utils/Images/mmdimg5.jpeg";

function Home() {
  return (
    <div className="header-slider">
      <div id="carouselExampleIndicators" className="carousel slide">
        
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={0}
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          />
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={1}
            aria-label="Slide 2"
          />
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={2}
            aria-label="Slide 3"
          />
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={mmdimg1} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={mmdimg2} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={mmdimg3} className="d-block w-100" alt="..." />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className="middle">
        <div className="say-with-men">
          <div className="container">
            <div
              className="say-with-hedding"
              data-aos="fade-down"
              data-aos-duration={2000}
            >
              {/* <h6>About</h6> */}
              {/* <hr className="border-a" /> */}
              <h4>About Us</h4>
              {/* <img class="border-icon" src="images/border-icon.png"> */}
            </div>
            <div className="row why-book-center align-items-center">
              <div className="col-lg-6 col-md-12">
                <div
                  className="say-with-right"
                  data-aos="fade-up"
                  data-aos-duration={2000}
                >
                  <img className="img-fluid" src={mmdimg4} />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div
                  className="say-with-left"
                  data-aos="fade-up"
                  data-aos-duration={2000}
                >
                  <h6>SKYMMD Homestay </h6>
                  <p>
                    Welcome to our cozy and well-equipped urban homestay,
                    designed to offer you the perfect blend of comfort and
                    convenience. Nestled in the heart of the Pink City, this
                    space is ideal for travelers, remote workers, and urban
                    explorers.
                  </p>
                  <ul>
                    <li data-aos="fade-up" data-aos-duration={3000}>
                      <i className="fa-regular fa-circle-check" /> High-Speed
                      Wi-Fi
                    </li>
                    <li data-aos="fade-up" data-aos-duration={3000}>
                      <i className="fa-regular fa-circle-check" /> Modern
                      Interiors
                    </li>
                    <li data-aos="fade-up" data-aos-duration={3000}>
                      <i className="fa-regular fa-circle-check" /> Comfortable
                      Bedrooms
                    </li>
                    <li data-aos="fade-up" data-aos-duration={3000}>
                      <i className="fa-regular fa-circle-check" /> Prime
                      Location
                    </li>
                    <li data-aos="fade-up" data-aos-duration={3000}>
                      <i className="fa-regular fa-circle-check" /> Work-Friendly
                    </li>
                    <li data-aos="fade-up" data-aos-duration={3000}>
                      <i className="fa-regular fa-circle-check" /> Best rate
                      Guarantee
                    </li>
                    <li data-aos="fade-up" data-aos-duration={3000}>
                      <i className="fa-regular fa-circle-check" />
                      Free Parking
                    </li>
                    <li data-aos="fade-up" data-aos-duration={3000}>
                      <i className="fa-regular fa-circle-check" /> Air
                      Conditioning
                    </li>
                    <li data-aos="fade-up" data-aos-duration={3000}>
                      <i className="fa-regular fa-circle-check" /> In-House
                      Kitchen
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="say-with-men">
          <div className="container">
            <div
              className="say-with-hedding"
              data-aos="fade-down"
              data-aos-duration={3000}
            >
              <h6>Room Gallery</h6>
              <hr className="border-a" />
              <h4>Room Types</h4>
              {/* <img class="border-icon" src="images/border-icon.png"> */}
            </div>
            <div className="row why-book-center">
              <div className="col-lg-6 col-md-6">
                <div className="room-types-men">
                  <img className="img-fluid" src={mmdimg1} />
                  <h6>EXECUTIVE ROOMS- KING BED</h6>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="room-types-men">
                  <img className="img-fluid" src={mmdimg3} />
                  <h6> DELUXE ROOMS- KING BED</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
