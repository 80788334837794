import React from "react";

function TermCond() {
  return (
    <div className="middle">
      <div className="say-with-men">
        <div className="container">
          <div
            className="say-with-hedding"
            data-aos="fade-down"
            data-aos-duration={2000}
          >
            <h6> Terms</h6>
            <hr className="border-a" />
            <h4> Terms and Conditions</h4>
            {/* <img class="border-icon" src="images/border-icon.png"> */}
          </div>
          <div className="row why-book-center align-items-center">
            <div className="col-lg-12 col-md-12">
              <div
                className="say-with-left"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <div className="policy-text">
                  <h6>Terms and Conditions</h6>
                  <p>
                    Welcome to Skymmd Homestay! By making a reservation or
                    staying with us, you agree to the following terms and
                    conditions. Please read them carefully.
                  </p>
                </div>
                <div className="policy-text">
                  <h6>1. Reservations and Payments</h6>
                  <p>
                    <strong> Booking Confirmation:</strong> A reservation is
                    confirmed upon receipt of payment or deposit as specified
                    during the booking process.
                  </p>
                  <p>
                    <strong>Payment Methods:</strong>We accept major bank
                    transfers and UPI Payments
                  </p>
                  <p>
                    <strong> Cancellation Policy:</strong> A reservation is
                    Cancellations must be made at least 72 hours before check-in
                    to avoid charges. Refer to our cancellation policy for
                    details.
                  </p>
                </div>
                <div className="policy-text">
                  <h6>2.Check-In and Check-Out</h6>
                  <p>
                    <strong> Check-In:</strong> Check-in time begins at 2:00 PM.
                    Early check-ins are subject to availability and may incur
                    additional charges.
                  </p>
                  <p>
                    <strong> Check-Out:</strong>Check-out time is by 11:00 AM,
                    Late check-outs may result in additional fees.
                  </p>
                  <p>
                    <strong> Check-In:</strong> Check-in time begins at 2:00 PM.
                    Early check-ins are subject to availability and may incur
                    additional charges.
                  </p>
                  <p>
                    <strong> Identification</strong>All guests must present
                    valid identification (passport, national ID, etc.) at
                    check-in.
                  </p>
                </div>
                <div className="policy-text">
                  <h6>3.Guest Responsibilities</h6>
                  <p>
                    <strong>Conduct: </strong>
                    Guests must respect the property, staff, and other guests.
                    Any behavior deemed disruptive or inappropriate may result
                    in eviction without a refund.
                  </p>
                  <p>
                    <strong>Damage and Loss:</strong>
                    Guests are responsible for any damage caused to the property
                    or its contents. Costs for repair or replacement will be
                    charged accordingly.
                  </p>
                  <p>
                    <strong> No Smoking Policy:</strong>
                    Smoking is strictly prohibited in non-smoking areas. A
                    penalty of INR 2000 will apply for violations.
                  </p>
                  <p>
                    <strong> Pets: </strong>Pets are not allowed in hotel
                    premises and rooms.
                  </p>
                </div>

                <div className="policy-text">
                  <h6>4.Property Use</h6>
                  <p>
                    <strong> Personal Belongings:</strong>
                    Guests are responsible for the safety of their personal
                    belongings. The property is not liable for lost or stolen
                    items.
                  </p>
                  <p>
                    <strong>Amenities:</strong>
                    Use of facilities such as the Play Area or Wi-Fi is at the
                    guest's own risk. Guests must adhere to any rules provided.
                  </p>
                  <p>
                    <strong>Third-Party Visitors:</strong>
                    Visitors are not allowed without prior approval from
                    management.
                  </p>
                  <p>
                    <strong> Pets: </strong>Pets are not allowed in hotel
                    premises and rooms.
                  </p>
                </div>

                <div className="policy-text">
                  <h6>5.Liability and Indemnity</h6>
                  <p>
                    <strong> Liability Limit:</strong>
                    The property is not responsible for injuries, accidents, or
                    losses incurred during your stay, except as required by law.
                  </p>
                  <p>
                    <strong>Indemnity:</strong>
                    Guests agree to indemnify and hold harmless SKYMMD HOMESTAY
                    from claims, damages, or expenses resulting from their stay
                    or breach of these terms.
                  </p>
                </div>

                <div className="policy-text">
                  <h6>6.Cancellation and Refunds</h6>
                  <p>
                    <strong> Refunds:</strong>
                    Refunds for cancellations depend on the notice period and
                    terms specified during booking.
                  </p>
                  <p>
                    <strong>Force Majeure:</strong>
                    The property is not liable for cancellations or disruptions
                    caused by events beyond its control (e.g., natural
                    disasters, pandemics).
                  </p>
                </div>
                <div className="policy-text">
                  <h6>7.Privacy & Policy </h6>
                  <p>
                  
                    Personal information collected during the booking process
                    will be handled per our Privacy Policy.
                  </p>
                </div>
                <div className="policy-text">
                  <h6> 8.Changes to terms </h6>
                  <p>
                    We reserve the right to update these terms and conditions at
                    any time. Guests will be notified of significant changes.
                  </p>
                </div>
                <div className="policy-text">
                  <h6> 9.Governing Law</h6>
                  <p>
                    These terms are governed by the laws of Government. Any
                    disputes will be resolved in courts located in Jaipur,
                    Rajasthan.
                  </p>
                </div>
                <div className="policy-text">
                  <h6> 10.Contact Information</h6>
                  <p>
                    For questions or assistance, please contact us: Email:
                    Megha@skymmd.com Phone: 6350482876 Address: Corporate office : A-118 Mahal Yojna, Akshay Patra Chouraha, Near By Vivacity Mall , Jagatpura, Jaipur, Rajasthan - 302022
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermCond;
