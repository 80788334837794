import React from "react";

function Privacypolicy() {
  return (
    <div className="middle">
      <div className="say-with-men">
        <div className="container">
          <div
            className="say-with-hedding"
            data-aos="fade-down"
            data-aos-duration={2000}
          >
            <h6> Privacy Policy</h6>
            <hr className="border-a" />
            <h4> Privacy Policy</h4>
            {/* <img class="border-icon" src="images/border-icon.png"> */}
          </div>
          <div className="row why-book-center align-items-center">
            <div className="col-lg-12 col-md-12">
              <div
                className="say-with-left"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <div className="policy-text">
                  <h6>1. Introduction</h6>
                  <p>
                    Welcome to Sky MMD. We are committed to protecting your
                    privacy and ensuring a safe experience for all our users.
                    This Privacy Policy explains how we collect, use, and
                    safeguard your personal information when you use our
                    platform, which connects travelers with hosts offering
                    accommodations and other services.
                  </p>
                </div>
                <div className="policy-text">
                  <h6>2. Information We Collect</h6>
                  <p>We may collect the following types of information:</p>
                  <div className="m-3">
                    <div className="policy-text">
                      <p>
                        <strong>a. Personal Information:</strong>
                      </p>
                      <p>
                        • Name, contact details (email, phone number, address)..
                      </p>
                      <p>• Payment information </p>
                      <p>
                        • Identification documents (passport, government-issued
                        ID).
                      </p>
                    </div>
                    <div className="policy-text">
                      <p>
                        <strong>b. Booking Information:</strong>
                      </p>
                      <p>• Dates of stay, preferences, and special requests.</p>
                    </div>
                    <div className="policy-text">
                      <p>
                        <strong>c.Technical Information:</strong>
                      </p>
                      <p>
                        • IP address, browser type, device information, and
                        usage statistics when you visit our website.
                      </p>
                    </div>
                    <div className="policy-text">
                      <p>
                        <strong>d. Other Information:</strong>
                      </p>
                      <p>•Feedback, reviews, and survey responses.</p>
                    </div>
                  </div>
                </div>
                <div className="policy-text">
                  <h6>3. How We Use Your Information</h6>
                  <p>We use your information for the following purposes:</p>
                  <div className="m-3">
                    <div className="policy-text">
                      <p>
                        •
                        <strong>
                          To process bookings and provide services:
                        </strong>{" "}
                        Confirm reservations, process payments, and manage your
                        stay.
                      </p>
                      <p>
                        • <strong>To enhance customer experience:</strong>{" "}
                        Customize services based on your preferences and respond
                        to your requests.
                      </p>
                      <p>
                        • <strong>To improve our business:</strong> Analyze data
                        to improve our website, offerings, and operations.
                      </p>
                      <p>
                        • <strong>To comply with legal obligations:</strong>{" "}
                        Maintain records as required by local laws and
                        regulations.
                      </p>
                      <p>
                        • <strong>Marketing purposes: </strong> Send promotional
                        offers, newsletters, or updates (only with your
                        consent).
                      </p>
                    </div>
                  </div>
                </div>
                <div className="policy-text">
                  <h6>4. Sharing Your Information</h6>
                  <p>
                    We do not sell your personal information. However, we may
                    share your information in the following circumstances:
                  </p>
                  <div className="m-3">
                    <div className="policy-text">
                      <p>
                        <strong>a.Service providers:</strong>
                      </p>
                      <p>
                        • With trusted third parties for payment processing, IT
                        services, or marketing assistance.
                      </p>
                    </div>
                    <div className="policy-text">
                      <p>
                        <strong>b. Legal compliance:</strong>
                      </p>
                      <p>
                        •When required by law or to protect our rights and
                        property.
                      </p>
                    </div>
                    <div className="policy-text">
                      <p>
                        <strong>c. With your consent</strong>
                      </p>
                      <p>
                        • When you explicitly agree to share your information.
                      </p>
                    </div>
                    <div className="policy-text">
                      <p>
                        <strong>d. Business Transfers:</strong>
                      </p>
                      <p>
                        • In the event of a merger, acquisition, or sale of
                        assets, your information may be transferred to the
                        acquiring entity.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="policy-text">
                  <h6>5. Data Security</h6>
                  <p>
                    We implement appropriate technical and organizational
                    measures to protect your personal information from
                    unauthorized access, loss, or misuse. However, no method of
                    transmission over the Internet is 100% secure.
                  </p>
                </div>
                <div className="policy-text">
                  <h6>6. Retention of Data</h6>
                  <p>
                    We retain your personal information only as long as
                    necessary to fulfill the purposes outlined in this policy or
                    comply with legal obligations.
                  </p>
                </div>
                <div className="policy-text">
                  <h6>7.Your Rights</h6>
                  <p>
                    •<strong>Access and correction:</strong> Request access to
                    or correction of your personal data.
                  </p>
                  <p>
                    •<strong>Erasure:</strong> Request the deletion of your data
                    under certain conditions.
                  </p>
                  <p>
                    •<strong>Data portability:</strong> Obtain a copy of your
                    data in a portable format.
                  </p>
                  <p>
                    •<strong>Withdraw consent: </strong> Opt-out of marketing
                    communications or other consent-based data processing.
                  </p>
                </div>
                <div className="policy-text">
                  <h6>8. Cookies and Tracking Technologies</h6>
                  <p>
                    Our website uses cookies to enhance your browsing experience
                    and gather analytics. You can manage your cookie preferences
                    in your browser settings.
                  </p>
                </div>
                <div className="policy-text">
                  <h6>9. Third-Party Links</h6>
                  <p>
                    Our website may include links to third-party sites. We are
                    not responsible for the privacy practices of those websites
                    and encourage you to review their policies.
                  </p>
                </div>
                <div className="policy-text">
                  <h6>10. Updates to This Privacy Policy</h6>
                  <p>
                    We may update this Privacy Policy from time to time. Any
                    changes will be posted on our website with the updated
                    effective date.
                  </p>
                </div>
                <div className="policy-text">
                  <h6> Contact Us</h6>
                  <p className="email-di">Email: megha@skymmd.com</p>
                  <p>Phone:6350482876</p>
                  <p>
                    Address: Corporate office : A-118 Mahal Yojna, Akshay Patra Chouraha, Near By Vivacity Mall , Jagatpura, Jaipur, Rajasthan - 302022
                  </p>
                </div>
                <div className="policy-text">
                  <p>
                    By using our platform, you acknowledge that you have read
                    and understood this Privacy Policy and agree to its terms.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacypolicy;
